import React, { useState, useEffect } from 'react';
import classes from './AddressFormModal.module.css';
import cross from '../assets/candleflower-modal-img/x.png';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from '../features/modal/modalSlice';
import { useForm } from 'react-hook-form';

const AddressFormModal = ({ onSubmit, type }) => {
  const { addressModal } = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  // State management for address components
  const [city, setCity] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [postBox, setPostBox] = useState('');
  
  // Initialize useForm hook
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  // Update fullAddress whenever any component changes
  const updateFullAddress = (street, city, post) => {
    const fullAddress = `${street.trim()} ${city.trim()} ${post.trim()}`;
    // Update the fullAddress using setValue from react-hook-form
    setValue('address', fullAddress);
  };

  useEffect(() => {
    updateFullAddress(streetAddress, city, postBox);
  }, [streetAddress, city, postBox, setValue]); // Add setValue to dependencies

  return (
    <React.Fragment>
      <Modal
        show={addressModal}
        onHide={() => dispatch(hideModal('addressModal'))}
        size="lg"
        centered
      >
        <Modal.Header style={{ border: 0, background: '#1A4E74' }}>
          <h5 className={`${classes.modal_title} m-auto`} dir="rtl">
            כתובת משלוח ל-QR שלכם!
          </h5>
          <button
            type="button"
            className={`${classes.btn_close} btn align-self-start`}
            onClick={() => dispatch(hideModal('addressModal'))}
          >
            <img src={cross} alt="Close" />
          </button>
        </Modal.Header>
        <Modal.Body className={classes.modal_body}>
          <div className={`row ${classes.input_container}`}>
            <div className="col-md-8 col-12 m-auto">
              <div className="row">
                <div className={classes.address_form_text}>
                  <p>
                    * יש להזין את פרטי יצירת הקשר עם האדם שיקבל את החבילה וכתובת המשלוח אליה תרצו לקבל את הברקודים החדשים שהזמנתם.
                  </p>
                </div>
                <div className="col-12">
                  <div className={classes.input_item}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=" *שם מלא"
                      {...register('fullName', { required: true })}
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className={classes.input_item}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="*מס' טלפון"
                      {...register('phone2', { required: true })}
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className={classes.input_item} style={{ marginTop: 10 }}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="*כתובת מלאה (שם רחוב, מספר בית, מספר דירה)"
                      value={streetAddress}
                      onChange={(e) => {
                        setStreetAddress(e.target.value);
                        updateFullAddress(e.target.value, city, postBox);
                      }}
                      required
                    />
                  </div>
                  <div className={classes.input_item} style={{ marginTop: 10 }}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="*עיר"
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                        updateFullAddress(streetAddress, e.target.value, postBox);
                      }}
                      required
                    />
                  </div>
                  <div className={classes.input_item} style={{ marginTop: 10 }}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="מיקוד"
                      value={postBox}
                      onChange={(e) => {
                        setPostBox(e.target.value);
                        updateFullAddress(streetAddress, city, e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          {/* Form submission button */}
          <div className={`${classes.button_container}`}>
            <button
              disabled={city.trim() === '' || streetAddress.trim() === ''}
              onClick={(e) => {
                handleSubmit((data) => onSubmit(data, type))(e);
              }}
            >
              להמשך ורכישה
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default AddressFormModal;
